import React from "react";
import ChatInterface from "./components/chatInterface/ChatInterface";
import ChatPopUp from "./components/chatPopUp/ChatPopUp";
import './App.css'

export interface Props {
	client_id?: string;
	quick_prompts?: string[];
	theme?: string;
	first_name?: string;
	meta_data?: {};
	session_id?:string;
	button_size?: string;
}

function App({ client_id, theme, quick_prompts, first_name, meta_data, session_id, button_size }: Props) {
	return (
		<div className="app">
			<ChatInterface button_size={button_size} theme={theme}/>
			<ChatPopUp
				client_id={client_id}
				quick_prompts={quick_prompts}
				first_name={first_name}
				meta_data={meta_data}
				session_id={session_id}
			/>
		</div>
	);
}

export default App;
