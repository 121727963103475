import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.scss";
import { modalStyleInject } from "../../injectedStyles/modalStyle";

interface PROPS {
  isOpen: boolean;
  setTriggerClose?: React.Dispatch<React.SetStateAction<boolean>>;
  triggerClose?: boolean;
  onClose: () => void;
  content: React.ReactNode;
}
const Modal: React.FC<PROPS> = ({
  isOpen,
  onClose,
  content,
  triggerClose,
  setTriggerClose,
}) => {
  const [modalActive, setModalActive] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setModalActive(isOpen);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (setTriggerClose) {
      setModalActive(false);
      setTriggerClose(false);
      setTimeout(() => {
        onClose();
      }, 350);
    } else {
      setModalActive(false);
      setTimeout(() => {
        onClose();
      }, 350);
    }
  }, [onClose, setTriggerClose]);

  useEffect(() => {
    if (triggerClose) {
      handleClose();
    }
  }, [triggerClose, handleClose]);

  const modalStyle = () => {
    if (modalActive) {
      return `modalContent active  ${styles.modalContent} ${styles.active}`;
    } else {
      return `modalContent ${styles.modalContent}`;
    }
  };

  if (!isOpen) {
    return null;
  }

  // Use React Portal to render the modal outside the regular DOM tree
  return ReactDOM.createPortal(
    <main className={`modal ${styles.modal}`}>
      <style>{modalStyleInject()}</style>
      <div className={`modalContainer ${styles.modalContainer}`}>
        <div className={` ${modalStyle()}`}>{content}</div>
      </div>
    </main>,
    document.body
  );
};

export default Modal;
