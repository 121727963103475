import React, { useEffect, useRef, useState } from "react";
import styles from "./ChatPopUp.module.scss";
import useGeneralState from "../../hooks/useGeneralState";
import { GeneralState } from "../../types";
import { logo, close, trigger } from "../../assets/index";
import Modal from "../modal/Modal";
import { useChatInterfaceModal } from "../../hooks/usePopUpModals";
import { ModalType } from "../../hooks/usePopUpModals";
import useChat from "../../store/chatStore";
import { ChatState } from "../../store/chatStore";
import UseMarkdown from "../../hooks/useMarkdown";
import { scrollPage } from "../../utils/scroll";
import { sendDisabled, stop } from "./svgs";
import { chatPopupStyle } from "../../injectedStyles/chatPopup";
const CDN_URL = process.env.REACT_APP_CDN_URL;

export interface Props {
	client_id?: string;
	quick_prompts?: string[];
	first_name?: string;
	meta_data?: {};
	session_id?: string;
}

const ChatPopUp = ({ client_id, quick_prompts, first_name, meta_data, session_id }: Props) => {
	const [randomPrompts, setRandomPrompts] = useState<string[]>([]);
	const stopStreamFlag = useRef(false);
	const abortControllerRef = useRef<AbortController | null>(null);
	const { theme } = useGeneralState((state: GeneralState) => state);
	const { isOpen, onClose } = useChatInterfaceModal((state: ModalType) => state);
	const { Messages, addMessage, setToolFeedback } = useChat((state: ChatState) => state);
	const [triggerClose, setTriggerClose] = useState(false);
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const scrollContainerRef = useRef<HTMLElement | null>(null);
	const [height, setHeight] = useState("auto");
	const [text, setText] = useState("");
	const [generating, setGenerating] = useState(false);
	const [streaming, setStreaming] = useState(false);
	console.log(Messages, generating, streaming);

	useEffect(() => {
		if (quick_prompts && quick_prompts.length > 0) {
			const shuffledPrompts = [...quick_prompts].sort(() => 0.5 - Math.random());
			setRandomPrompts(shuffledPrompts.slice(0, 3));
		}
	}, [quick_prompts]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(event.target.value);
	};

	useEffect(() => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
			setHeight(`${textarea.scrollHeight}px`);
		}
	}, [text]);

	const handleClose = () => {
		setTriggerClose(true);
	};

	const stopStream = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
			abortControllerRef.current = null;
		}
		setGenerating(false);
		setStreaming(false);
		stopStreamFlag.current = true;
	};

	let intentId = "";
	let intentValue = "";
	let currentEvent = "";
	let currentData = "";
	let messageBuffer = "";

	const chatClive = async () => {
		const user_name = "snapshengr";
		const pass_word = "NyQMav!mp#.";
		setGenerating(true);
		const basicAuth = "Basic " + btoa(user_name + ":" + pass_word);

		try {
			abortControllerRef.current = new AbortController();
			const response = await fetch(
				`https://dev.ai.gateway.cliveai.com/api/v2/chat-snapshot-stream`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: basicAuth,
					},
					body: JSON.stringify({
						sentence: text,
						partnerId: client_id ?? "-",
						sessionId: session_id ?? "-",
						metadata: meta_data ?? {},
					}),
					signal: abortControllerRef.current?.signal,
				}
			);

			if (response.ok) {
				const reader = response.body && response.body.getReader();
				const decoder = new TextDecoder("utf-8");
				let partialMessage = "";

				if (reader) {
					setStreaming(true);
					stopStreamFlag.current = false;
					while (!stopStreamFlag.current) {
						const { done, value } = await reader.read();
						if (done) {
							setStreaming(false);
							break;
						}
						partialMessage += decoder.decode(value, { stream: true });
						const messages = partialMessage.split("\n\n");

						for (const message of messages.slice(0, -1)) {
							const lines = message.split("\n");

							for (const line of lines) {
								if (line.startsWith("event:")) {
									if (currentEvent) {
										handleEvent(currentEvent, currentData.trim());
										currentEvent = "";
										currentData = "";
									}
									currentEvent = line.replace("event: ", "").trim();
								} else if (line.startsWith("data:")) {
									currentData += line.replace("data:", "");
									if (currentEvent === "message_start") {
										setToolFeedback("");
										updateMessageBuffer(line.replace("data:", "").trim());
									} else if (currentEvent === "tool_feedback") {
										setToolFeedback(currentData.trim());
									}
								}
							}
						}

						partialMessage = messages[messages.length - 1];
					}
				}
				if (currentEvent) {
					handleEvent(currentEvent, currentData.trim());
				}
			} else {
				throw new Error("Failed to send message");
			}
		} catch (error) {
			if (error instanceof Error && error.name !== "AbortError") {
				console.error("Error in chatClive:", error);
			}
		} finally {
			setGenerating(false);
			setStreaming(false);
		}
	};

	const handleEvent = (eventType: string, data: string) => {
		const formatted = data.replace(/\\n/g, "<br/>");
		if (eventType === "start_stream") {
			handleMessageStart(formatted);
		} else if (eventType === "message_end") {
			handleMessageEnd();
		} else if (eventType === "tool_response") {
			handleToolResponse(formatted);
		} else {
			//console.log(`Unknown event: ${eventType}`, formatted);
		}
	};

	const handleMessageStart = (data: string) => {
		messageBuffer = data;
	};

	const handleMessageEnd = () => {
		messageBuffer = "";
		setGenerating(false);
	};

	const handleToolResponse = (data: string) => {
		const parsedData = JSON.parse(data);
		const updatedMessage = {
			responses: [
				{ clive_response: messageBuffer.trim(), tools_response: parsedData },
				{ intent: intentValue, intentId },
			],
			type: "received",
		};

		addMessage(updatedMessage);

		const timeout = setTimeout(() => {
			scrollPage(true);
		}, 2000);
		return () => clearTimeout(timeout);
	};

	const updateMessageBuffer = (data: string) => {
		scrollPage();
		messageBuffer = data;
		const formattedMessage = messageBuffer.replace(/\\n/g, "<br/>");

		const currentMessages = useChat.getState().Messages;
		const lastMessageIndex = currentMessages.length - 1;

		if (lastMessageIndex >= 0) {
			const lastMessage = currentMessages[lastMessageIndex];
			lastMessage.responses = lastMessage.responses || [];
			if (lastMessage.responses.length > 0) {
				lastMessage.responses[0].clive_response = formattedMessage.trim();
			} else {
				lastMessage.responses.push({
					clive_response: formattedMessage.trim(),
					tools_response: [],
				});
			}
			useChat.setState({ Messages: [...currentMessages] });
		} else {
			const updatedMessage = {
				responses: [
					{ clive_response: formattedMessage.trim(), tools_response: [] },
					{ intent: intentValue, intentId },
				],
				type: "received",
			};
			addMessage(updatedMessage);
		}

		// streamTTS(data.trim());
	};

	const sendMessage = async () => {
		scrollPage();
		setText("");
		addMessage({
			prompt: text,
			type: "sent",
			fileName: "",
			fileUrl: "",
			imageUrl: "",
		});

		chatClive();
	};

	useEffect(() => {
		const scrollLastContentToTop = () => {
			const scroll = scrollContainerRef.current;

			if (scroll) {
				const lastElement = scroll.lastElementChild;
				if (lastElement) {
					const lastContentHeight = lastElement.clientHeight + 130;
					scroll.scrollTo({
						top: scroll.scrollHeight - lastContentHeight,
					});
				}
			}
		};

		scrollLastContentToTop();
	}, [Messages]);

	const content = (
		<main className={`main ${styles.main} ${styles[theme]} BaseFont`}>
			<style>{chatPopupStyle()}</style>
			<div className={`chatContent ${styles.chatContent}`}>
				<section className={`header ${styles.header}`}>
					{Messages?.length > 0 ? (
						<div className={`logoContainer ${styles.logoContainer}`}>
							<img
								src={`${CDN_URL}${logo}`}
								alt="logo"
								className={`logo ${styles.logo}`}
							/>
						</div>
					) : (
						<p></p>
					)}
					<img
						src={`${CDN_URL}${close}`}
						alt="close"
						onClick={handleClose}
						className={`close ${styles.close}`}
					/>
				</section>
				{Messages.length < 1 ? (
					<section className={`welcome ${styles.welcome}`}>
						<div className={`top ${styles.top}`}>
							<img
								src={`${CDN_URL}${logo}`}
								alt="alt"
								className={`welcomeLogo ${styles.welcomeLogo}`}
							/>
							<p className={`greeting ${styles.greeting}`}>
								Hi {first_name}, How can Clive help you today?
							</p>
						</div>

						<div className={`bottom ${styles.bottom}`}>
							{randomPrompts?.map((itm, idx) => {
								return (
									<button
										key={idx}
										onClick={() => {
											setText(itm);
											textareaRef.current?.focus();
										}}
										style={{ fontFamily: "Montserrat" }}
										className={`randomPrompt ${styles.randomPrompt}`}
									>
										{itm}
									</button>
								);
							})}
						</div>
					</section>
				) : (
					<section
						id="chatContainer"
						ref={scrollContainerRef}
						className={`chatContainer ${styles.chatContainer} ${styles[theme]}`}
					>
						{Messages.map((message: any, index: number) => {
							return (
								<div
									// ref={messages?.length - 1 === index ? lastChatContentRef : null}
									key={index}
									className={`chats ${styles.chats}`}
								>
									{message.type !== "widget" && (
										<>
											<div className={`promptGroupp ${styles.promptGroupp}`}>
												{message?.prompt && (
													<div
														className={`promptWrapper ${
															styles.promptWrapper
														} ${
															index !== 0 && styles.promptWrapperAlt
														}`}
													>
														<p
															className={`prompt ${styles.prompt}`}
															style={{ fontFamily: "Montserrat" }}
														>
															{message.prompt}
														</p>
													</div>
												)}
											</div>

											{message?.responses?.length > 0 && (
												<>
													{(() => {
														try {
															const responseData =
																message.responses[0];
															if (responseData?.clive_response) {
																return (
																	<div className={`response ${styles.responseContainer}`}>
																		<img
																			src={`${CDN_URL}${trigger}`}
																			alt="trigger"
																			className={`triggerImg ${styles.snapshot}`}
																		/>
																		<div
																			className={`response ${styles.response}`}
																			style={{
																				fontFamily:
																					"Montserrat",
																			}}
																		>
																			<UseMarkdown
																				ClassName={`response ${styles.response}`}
																				children={responseData?.clive_response?.trim()}
																			/>
																		</div>
																	</div>
																);
															} else {
																return (
																	<div
																		className={`response ${styles.response}`}
																		style={{
																			fontFamily:
																				"Montserrat",
																		}}
																	>
																		<UseMarkdown
																			ClassName={`response ${styles.response}`}
																			children={message?.responses[0]?.trim()}
																		/>
																	</div>
																);
															}
														} catch (error) {
															//
														}
													})()}
												</>
											)}
										</>
									)}
								</div>
							);
						})}
					</section>
				)}
				<div className={`bottomA ${styles.bottomA}`}>
					<section className={`textArea ${styles.textArea}`}>
						<textarea
							rows={1}
							id="prompt_box"
							ref={textareaRef}
							style={{ height, fontFamily: "Montserrat" }}
							onKeyDown={(e) => {
								if (
									e.key === "Enter" &&
									!e.shiftKey &&
									text.trim().length > 0 &&
									!generating
								) {
									e.preventDefault();
									sendMessage();
								}
							}}
							value={text}
							placeholder={"Ask Clive Anything"}
							className={`text ${styles.text}`}
							onChange={handleChange}
						/>
						<section className={`actionBtns ${styles.actionBtns}`}>
							{!generating && (
								<button
									disabled={text.trim().length < 1}
									onClick={sendMessage}
									className={`sendBtn  ${styles.sendBtn} ${
										text.trim().length < 1 && `sendBtnAlt ${styles.sendBtnAlt}`
									}`}
								>
									{sendDisabled}
								</button>
							)}

							{generating && (
								<button
									onClick={stopStream}
									className={`sendBtn ${styles.sendBtn}`}
								>
									{stop}
								</button>
							)}
						</section>
					</section>
				</div>
			</div>
		</main>
	);

	return (
		<Modal
			triggerClose={triggerClose}
			setTriggerClose={setTriggerClose}
			isOpen={isOpen}
			onClose={onClose}
			content={content}
		/>
	);
};

export default ChatPopUp;
