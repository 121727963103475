export const chatPopupStyle = () => {
	return `
		@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
		.main {
			height: 100%;
			z-index: 2147483647 !important;
		}

		.chatContent {
			pointer-events: all;
			position: absolute;
			top: 1rem;
			bottom: 1rem;
			right: 0rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			backdrop-filter: blur(6px) saturate(200%);
			-webkit-backdrop-filter: blur(6px) saturate(200%);
			background-color: rgba(77, 92, 91, 0.66);
			border-radius: 28px;
			border: 1px solid rgba(255, 255, 255, 0.125);
			z-index: 10000 !important;
			width: 100%;
		}

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 24px 32px;
		}

		.logoContainer {
			width: max-content;
			height: 34px;
		}

		.logo {
			width: 100%;
			height: 100%;
		}

		.close {
			cursor: pointer;
		}

		.welcome {
			padding-inline: 41px;
			height: 100%;
			margin-top: 30px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.top {
			display: flex;
			flex-direction: column;
			gap: 35px;
		}

		.welcomeLogo {
			width: max-content;
			height: 44px;
		}

		.greeting {
			font-size: 24px;
			max-width: 350px;
			color: #e7e7e7;
			font-weight: 400;
		}

		.bottom {
			padding: 0;
			width: 100%;
			background: transparent;
			display: flex;
			flex-direction: column;
			gap: 18px;
			margin-bottom: 62px;
		}

		.randomPrompt {
			width: 100%;
			color: #f4f4f4;
			font-family: "Montserrat", sans-serif;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px 16px;
			border-radius: 20px;
			font-size: 14px;
			background: linear-gradient(
				142.96deg,
				rgba(202, 202, 202, 0.15) -3.54%,
				rgba(160, 160, 160, 0.138) 95.15%
			);
		}

		.chatContainer {
			background-color: transparent;
			position: relative;
			height: 100%;
			overflow-y: auto;
			scrollbar-width: none;
			-ms-overflow-style: none;
			padding: 10px 32px;
			display: flex;
			flex-direction: column;
			align-content: flex-end;
			gap: 1rem;
		}

		.chats {
			display: flex;
			flex-direction: column;
			gap: 30px;
		}

		.promptGroupp {
			animation: typing 500ms ease-in-out forwards;
			max-width: 100%;
		}

		.promptWrapper {
			display: flex;
			flex-direction: column;
		}

		.prompt {
			align-self: flex-end;
			word-break: break-all;
			background: transparent;
			color: #ffff;
			font-size: 16px;
			font-weight: 400;
			border-radius: 6px;
		}

		.responseContainer {
			display: flex;
			gap: 17px;
		}

		.snapshot {
			width: 36px;
			height: 36px;
			border-radius: 50%;
		}
			
		.response {
			align-self: flex-start;
			background-color: transparent;
			color: #ffff;
			font-size: 16px;
			font-weight: 400;
			max-width: 100%;
		}

		@keyframes typing {
			from {
				opacity: 0;
				transform: translateY(10px);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		.bottomA {
			padding: 0px 32px 35px;
		}

		.textArea {
			font-size: 10px;
			display: flex;
			align-items: center;
			width: 100%;
			background: #011119;
			border: 1px solid #616664;
			border-radius: 28px;
			box-shadow: 5px 5px 15px 0px rgba(198, 198, 198, 0.2);
			padding-inline: 8px;
		}

		.text {
			white-space: pre-wrap;
			font-family: "Inter", sans-serif;
			font-size: 14px;
			width: 100%;
			padding: 1rem 0.7rem;
			color: #e7e7e7;
			font-weight: 400;
			resize: none;
			overflow-y: scroll;
			line-height: 20px;
			background-color: transparent;
			border-radius: 28px;
			max-height: 150px;
		}

		.text::-webkit-scrollbar {
			display: inline-flex;
			width: 0.4rem;
		}

		::-webkit-scrollbar-thumb {
			background: #77bea0;
			border-radius: 10px;
		}

		.text::placeholder {
			font-size: 14px;
			color: #a6a6a6;
			opacity: 0.7;
		}

		.actionBtns {
			align-self: flex-end;
			margin-bottom: 10.5px;
		}

		.sendBtn {
			background-color: #ffffff;
			display: flex;
			align-items: center;
			border-radius: 50%;
			cursor: pointer;
		}

		.sendBtnAlt {
			opacity: 0.3;
		}

		.trademark {
			text-align: center;
			margin-top: 10px;
			font-size: 12px;
			color: #babbbb;
		}

		.btns {
			cursor: pointer;
			margin-top: 2px;
		}

		@media screen and (min-width: 1024px) {
			.chatContent {
				width: 500px;
				right: 1rem;
			}

			.textArea {
				padding-inline: 10px;
			}
		}
	`;
};
