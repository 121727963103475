import { create } from "zustand";

export interface ChatState {
	Messages: any[];
	toolFeedback: string;
	setToolFeedback: (toolFeedback: string) => void;
	newChat: (messages: any) => void;
	addMessage: (message: any) => void;
	resetMessages: () => void;
}

const useChat = create<ChatState>((set) => ({
	Messages: [],
	toolFeedback: "",
	setToolFeedback: (toolFeedback) => set({ toolFeedback }),
	newChat: (newMessage) =>
		set(() => {
			const chatContainer = document.getElementById("chatContainer");
			if (chatContainer) {
				requestAnimationFrame(() => {
					chatContainer.scrollTo({
						top: chatContainer.scrollHeight,
						behavior: "auto",
					});
				});
			}
			return { Messages: newMessage };
		}),
	addMessage: (newMessage) => set((state:ChatState) => ({ Messages: [...state.Messages, newMessage] })),
	resetMessages: () => set({ Messages: [] }),
}));

export default useChat;
