import "./index.scss";
import "../src/fonts/freighttext-pro-/stylesheet.css";
import r2wc from "@r2wc/react-to-web-component";
import App from "./App";

// Define your web component with r2wc
const ChatComponent = r2wc(App, {
  shadow: "open", 
  props: {
    theme: "string",
    client_id: "string",
    quick_prompts: "json",
    first_name: "string",
    meta_data: "json",
    button_size: "string",
    session_id: "string",
  },
});


// Register the custom element using r2wc
customElements.define("snapshot-interface", ChatComponent);
