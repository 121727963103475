import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighterWrapper from "./forwardRefs";
import { preprocessChildren } from "../utils/replaceMarkDown";

const UseMarkdown = ({ children, ClassName }: { children: string; ClassName: string }) => {
	// keep track of clickable links

	const processedChildren =
		typeof children === "string" ? preprocessChildren(children) : children;

	// Check if the input contains code blocks
	const hasCodeBlocks = /```/.test(children ?? "");
	return hasCodeBlocks ? (
		<Markdown
			children={children}
			className={ClassName}
			remarkPlugins={[remarkGfm, remarkBreaks]}
			components={{
				code({ children, className, ...rest }) {
					const match = /language-(\w+)/.exec(className || "");
					return match ? (
						<SyntaxHighlighterWrapper {...rest} language={match[1]}>
							{children}
						</SyntaxHighlighterWrapper>
					) : (
						<code
							{...rest}
							className={className}
							style={{
								hyphens: "auto",
								whiteSpace: "preserve-breaks",
								fontWeight: 500,
								fontSize: "15px",
								color: "red",
							}}
						>
							{children}
						</code>
					);
				},
			}}
		/>
	) : (
		// If no code blocks, render processed children with dangerouslySetInnerHTML
		<div
			className={ClassName}
			dangerouslySetInnerHTML={{
				__html: processedChildren ?? "",
			}}
		/>
	);
};

export default UseMarkdown;
