export const scrollPage = (alt?: boolean) => {
	const chatContainer = document.getElementById("chatContainer");
	if (chatContainer) {
		return requestAnimationFrame(() => {
			chatContainer.scrollTo({
				top: chatContainer.scrollHeight + 1000,
				behavior: alt ? "smooth" : "auto",
			});
		});
	}
};
