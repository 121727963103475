import { create } from "zustand";

interface GeneralState{
    theme:string;
    setTheme:(theme:string)=>void;
}

const useGeneralState = create<GeneralState>((set) => ({
    theme: "light",
    setTheme: (theme: string) => set({ theme }),
}));

export default useGeneralState;